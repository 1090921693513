import Layout from "../Components/Layout/Layout";
import CodeSnippetGrid from "../Components/ProjectDetailed/CodeSnippetGrid";
import Learnings from "../Components/ProjectDetailed/Learnings";
import Overview from "../Components/ProjectDetailed/Overview";
import ProjectHero from "../Components/ProjectDetailed/ProjectHero";
import TechStack from "../Components/ProjectDetailed/TechStack";
const Hero = {
  title:"Maven Silicon",
  subHeading:"UI Development, Animations, API Intergration",
  backgroundImage:"/maven-silicon/web-mockup.jpg",
  websiteLink:"https://www.maven-silicon.com/"
}
const OverviewData = [
  "This front-end development project is built on the foundation of Next.js, employing SASS, React Bootstrap, Strapi, and Framer Motion to create a dynamic and engaging web application. Next.js, a powerful framework, enables us to build interactive and server-rendered components for a fast and responsive user interface.",
  "We enhance the user experience with SASS for styling and theming. React Bootstrap streamlines UI development with pre-designed components and responsive layouts.",
  "Additionally, Framer Motion is integrated for animations that bring a delightful and interactive dimension to our website. Strapi serves as content management system (CMS), streamlining content creation, management, and integration.",
  " In summary, our project leverages the capabilities of Next.js, SASS, React Bootstrap, Strapi, and Framer Motion to deliver a modern, feature-rich, and visually appealing web application, while relying on Strapi for efficient content management and data integration.",
];
const techStcak = [
  {
    icon:"/maven-silicon/strapi.webp",
    title:"STRAPI CMS"
  },
  {
    icon:"/technologies/icon-css.png",
    title:"CSS"
  },
  {
    icon:"/technologies/icon-sass.png",
    title:"SASS"
  },
  {
    icon:"/technologies/icon-js.png",
    title:"Javascript"
  },
  {
    icon:"/technologies/icon-react.png",
    title:"ReactJS"
  },
    {
    icon:"/easybids/nextjs-boilerplate-logo.png",
    title:"Nextjs"
  },
  {
    icon: "/technologies/icon-github.png",
    title:"GIT Hub"
  },
  {
    icon: "/technologies/icon-vs-code.png",
    title:"VS Code"
  },
  {
    icon:   "/maven-silicon/framer-motion.svg",
    title:"Framer Motion"
  },
];

const learningData = [
  "Nextjs Server-side rendering and static site generation",
  "Understanding routing and page structure within a Next.js application.",
  "Utilizing React Bootstrap pre-built components for rapid and responsive web development.",
  "Customizing  React Bootstrap components and styles to match specific design requirements",
  "Understanding how to handle responsive layouts and create user-friendly interfaces.",
  "Mastering the use of SASS variables, nesting, and mixins for efficient and maintainable CSS.",
  "Learning about SASS functions and operations to create dynamic styles.",
  "Understanding the concept of partials and imports to manage large-scale stylesheets effectively.",
  "Implementing smooth and visually appealing animations and transitions.",
  "Understanding the principles of animation and how to apply them effectively in web development."
]

const CodeSnippetGridData = [
  {
    codeSnippet:"/maven-silicon/button-secondary.png",
    description:"This component allows for the easy creation of different types of buttons with various functionalities and styles, making it flexible and reusable across different parts of a React application."
  },
  {
    codeSnippet:"/maven-silicon/mixins-text-sizes.png",
    description:"The code is designed to create a scalable and responsive text sizing system using Sass, allowing you to define text sizes once and apply them across different breakpoints with ease. However, to fully understand the code and how it fits into the overall structure of the stylesheets, additional context such as the values of the breakpoints and the usage of the mixin elsewhere in the codebase would be required."
  },
  {
    codeSnippet:"/maven-silicon/course-card.png",
    description:"This CourseCard component is designed to display a visually appealing card that showcases key details about a course, including the title, instructor, pricing, and other relevant information. It can be used in a variety of contexts within a React application, such as within a course catalog, a course detail page, or any other section that requires the display of course-related information."
  },
]
const MavenSilicon = () => {
  return(
    <Layout>
      <ProjectHero backgroundImage={Hero.backgroundImage} title={Hero.title} subHeading={Hero.subHeading} webLink={Hero.websiteLink} />
      <Overview listItemsData={OverviewData}/>
      <TechStack techStack={techStcak}/>
      <Learnings listItemsData={learningData}/>
      {/* <CodeSnippetGrid CodeSnippetGridData={CodeSnippetGridData}/> */}
    </Layout>
  )
}
export default MavenSilicon;