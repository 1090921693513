import { Container, Row, Col } from "react-bootstrap";
import { Linkedin, TelephoneFill } from "react-bootstrap-icons";
const Hero = () => {
  return (
    <section className="py-3 mt-3 mt-lg-5">
      <Container>
        <Row className="g-4">
          <Col md={12}>
            <div className="mk-card p-4 p-lg-5 h-100 d-flex  flex-column justify-content-between">
              <div>
                <h1 className="mb-3 text-light text-2xl text-lg-5xl">
                  Hello, I’m Manjunath, a UI Developer With 3 years of
                  experience.
                </h1>
                <p className="text-sm text-lg-lg text-light font-light opacity-5">
                  {/* I care a lot about using design for positive impact. and enjoy
                  creating user-centric, delightful, and human experiences. */}
                  Proficient UI Developer skilled in designing intuitive interfaces and elevating user engagement.
                </p>
              </div>
              <div className="d-flex align-items-center ">
                <a href="mailto:infoofmanjun@gmail.com" className="mk-button">
                  Contact Me
                </a>
                <a
                  href="https://www.linkedin.com/in/manjunath-kottarki-5858949b/"
                  target="_blank"
                  without
                  rel="noreferrer"
                  className="ms-4"
                >
                  <Linkedin className="fs-3 text-light" />
                </a>
                <a href="tel:9880274931" className="ms-3">
                  <TelephoneFill className="fs-3 text-light" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Hero;
