// Import Swiper React components
import { Col, Container, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

const TechStack = ({ techStack }) => {
  return (
    <section className="mt-5">
      <Container>
        <Row>
          <Col>
            <h4 className="text-3xl text-sm-4xl text-lg-6xl text-light text-center font-light mb-3">
              Built with
            </h4>
            <Swiper
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay]}
        className="py-3"
        loop={true}
        autoplay={{
          // delay: 800,
          disableOnInteraction: true,
        }}
      >
        {techStack &&
          techStack.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <figure className=" flex-column d-flex align-items-center justify-content-center">
                  <div className="mk-techstack__item">
                    <Image src={data.icon} className="mk-techstack__item-img" />
                  </div>

                  <figcaption className="text-sm text-lg-md text-light text-center font-light mt-2 mb-0">
                    {data.title}
                  </figcaption>
                </figure>
              </SwiperSlide>
            );
          })}
      </Swiper>
          </Col>
        </Row>
      </Container>
   
    </section>
  );
};
export default TechStack;
