import Layout from "../Components/Layout/Layout";
import Learnings from "../Components/ProjectDetailed/Learnings";
import Overview from "../Components/ProjectDetailed/Overview";
import ProjectHero from "../Components/ProjectDetailed/ProjectHero";
import TechStack from "../Components/ProjectDetailed/TechStack";
const Hero = {
  title: "EasyBids",
  subHeading: "UI Development",
  backgroundImage: "/thumbnails/easybids-banner-new-a.jpg",
  websiteLink: "https://easybids.com/",
};
const OverviewData = [
"By utilizing the powerful capabilities of Next.js and the flexible styling options provided by Styled Components, the project delivers a robust, responsive, and visually appealing web application.",
"The seamless integration of these technologies not only enhances the user experience but also ensures optimal performance and easy maintenance, laying a solid foundation for future enhancements and updates."
];
const techStcak = [
  {
    icon: "/technologies/icon-html.png",
    title: "HTML",
  },
  {
    icon: "/easybids/styled.png",
    title: "Styled Component",
  },
  {
    icon: "/technologies/icon-js.png",
    title: "Javascript",
  },
  {
    icon: "/technologies/icon-react.png",
    title: "ReactJS",
  },
  {
    icon: "/easybids/nextjs-boilerplate-logo.png",
    title: "Nextjs",
  },
  {
    icon: "/technologies/icon-github.png",
    title: "GIT Hub",
  },
  {
    icon: "/technologies/icon-vs-code.png",
    title: "VS Code",
  },
];

const learningData = [
  "Understanding how to efficiently style components using Styled Components within a Next.js project is crucial. It allows for a clean and organized approach to managing styles, promoting better code maintainability and reusability.",
  "Learning the benefits of server-side rendering through Next.js, such as improved performance and search engine optimization, can be significant. Understanding how to optimize rendering for different page types can lead to a better user experience and higher search engine rankings.",
  "Mastering responsive design techniques within a Next.js application ensures that the application is accessible and user-friendly across various devices and screen sizes. This includes understanding how to use media queries effectively and create fluid layouts that adapt to different resolutions.",
  "Recognizing the importance of component reusability and composition in Next.js applications can lead to more modular and maintainable code. Learning how to create reusable components and compose them effectively can significantly streamline the development process.",
];

const EasyBids = () => {
  return (
    <Layout>
      <ProjectHero
        backgroundImage={Hero.backgroundImage}
        title={Hero.title}
        subHeading={Hero.subHeading}
        webLink={Hero.websiteLink}
      />
      <Overview listItemsData={OverviewData} />
      <TechStack techStack={techStcak} />
      <Learnings listItemsData={learningData} />
    </Layout>
  );
};
export default EasyBids;