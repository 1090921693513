import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import MavenSilicon from "./pages/MavenSilicon";
import EasyBids from "./pages/EasyBids";
import Scelint from "./pages/Scelint";
import Zasti from "./pages/Zasti";
import MovieDB from "./pages/MovieDB";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/maven-silicon" element={<MavenSilicon />} />
        <Route path="/easybids" element={<EasyBids />} />
        <Route path="/scelint" element={<Scelint />} />
        <Route path="/zasti" element={<Zasti />} />
        <Route path="/movies" element={<MovieDB />} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;