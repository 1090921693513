import { CodeSlash } from "react-bootstrap-icons";

import { Container, Row, Col, ListGroup } from "react-bootstrap";
const Learnings = ({ listItemsData }) => {
  return (
    <section className="py-5 mt-3">
      <Container>
        <Row>
          <Col className="">
            <h4 className="text-3xl text-sm-4xl text-lg-6xl text-light text-start font-light mb-4">
              Learning
            </h4>
            <ListGroup className="bg-transparent">
              {listItemsData &&
                listItemsData.map((data, index) => {
                  return (
                    <ListGroup.Item
                      key={index}
                      className="text-md text-lg-lg text-light font-light bg-transparent border-0 p-0 mb-2 d-flex"
                    >
                     <span> <CodeSlash className="text-xl text-secondary" /></span>
                      <span className="ms-3 opacity-5">{data}</span>
                    </ListGroup.Item>
                  );
                })}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Learnings;
