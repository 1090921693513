import Layout from "../Components/Layout/Layout";
import Hero from "../Components/Hero";
import Projects from "../Components/Projects";
import TechIKnow from "../Components/TechIKnow";
// import VerticalTimeline from "../Components/VerticalTimeline";

const Home = () => {
  return (
    <>
      <Layout>
        <Hero />
        {/* <VerticalTimeline/> */}
        <Projects />
        <TechIKnow />
      </Layout>
    </>
  );
};
export default Home;