import { Container, Row, Col } from "react-bootstrap";
import { Linkedin, TelephoneFill } from "react-bootstrap-icons";
import OtherProjects from "../OtherProjects";
// const backgroundImage ="./footer-background.webp"
const Footer = () => {
  return(
   <>
   <OtherProjects/>
    <footer className="py-5 mk-footer">
    <Container>
      <Row className="g-4">
        <Col md={12}>
          <div className="mk-card p-4 p-lg-5 h-100 d-flex  flex-column justify-content-between">
            <div>
              <h1 className="mb-3 text-light text-2xl text-lg-5xl">
              Contact Me
              </h1>
              <p className="text-sm text-lg-lg text-light font-light opacity-5">
              Feel free to reach out for collaborations or just a friendly hello
              </p>
            </div>
            <div className="d-flex align-items-center">
              <a href="mailto:infoofmanjun@gmail.com" className="mk-button">
                Contact Me
              </a>
              <a
                href="https://www.linkedin.com/in/manjunath-kottarki-5858949b/"
                target="_blank"
                without
                rel="noreferrer"
                className="ms-4"
              >
                <Linkedin className="fs-3 text-light" />
              </a>
              <a href="tel:9880274931" className="ms-3">
                <TelephoneFill className="fs-3 text-light" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
   </>
  )
}
export default Footer;