import {  Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const logoLight = "/logo-light.svg";
const Navbar = () => {
  return (
    <nav className="w-100 background-dark mk-nav d-flex align-items-center position-sticky top-0">
      <Container>
        <Row>
          <Col className="d-flex justify-content-between">
            <div className="mk-nav-logo d-flex align-items-center">
            <Link to="/">  <Image src={logoLight} alt="Manjunath" fluid /></Link>
            </div>
            <div className="d-flex align-items-center">
            <a href="/resume/manjunath-kottarki.pdf" target="_blank" className="mk-button" >Resume</a>
        </div>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};
export default Navbar;
