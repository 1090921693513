import Layout from "../Components/Layout/Layout";
import Learnings from "../Components/ProjectDetailed/Learnings";
import Overview from "../Components/ProjectDetailed/Overview";
import ProjectHero from "../Components/ProjectDetailed/ProjectHero";
import TechStack from "../Components/ProjectDetailed/TechStack";
const Hero = {
  title: "Scelint",
  subHeading: "UI Development, Animations",
  backgroundImage: "/thumbnails/scelint-thumb-new-x-compressed.jpg",
  websiteLink: "https://www.scelint.com/",
};
const OverviewData = [
  "The project involves the creation of a responsive and visually appealing website using HTML, SASS, and Bootstrap 4. Leveraging the versatility of these technologies,",
  "The project focuses on delivering a user-friendly and interactive web interface while ensuring a streamlined development process.",
];
const techStcak = [
  {
    icon: "/technologies/icon-html.png",
    title: "HTML",
  },

  {
    icon: "/technologies/icon-sass.png",
    title: "SASS",
  },
  {
    icon: "/scelint/bootstrap.png",
    title: "Bootstrap 4",
  },
  {
    icon: "/technologies/icon-js.png",
    title: "Javascript",
  },
  {
    icon: "/technologies/icon-github.png",
    title: "GIT Hub",
  },
  {
    icon: "/technologies/icon-vs-code.png",
    title: "VS Code",
  },
];

const learningData = [
  " Understanding the importance of structured and semantic HTML markup is essential for creating a well-organized and accessible website. Learning how to use appropriate HTML elements and tags optimizes search engine visibility and ensures a better user experience.",
  "Mastering SASS preprocessing offers a more efficient way to write and manage CSS. Learning SASS features such as variables, nesting, and mixins can significantly streamline the styling process and promote code reusability, ultimately leading to a more maintainable codebase.",
  "Gaining proficiency in utilizing Bootstrap 4 components and responsive utilities is crucial for building a responsive and user-friendly website. Understanding how to implement Bootstrap's grid system and pre-built components can expedite the development process and ensure a consistent layout across different devices.",
  " Understanding responsive web design principles and techniques, including media queries and flexible grid layouts, is vital for creating a website that adapts seamlessly to various screen sizes. Learning how to prioritize content and adjust the layout based on device capabilities enhances user experience and accessibility.",
  "Exploring the customization options offered by SASS and Bootstrap 4 allows for the creation of a unique and visually appealing website design. Learning how to customize Bootstrap components with SASS helps maintain design consistency while providing the flexibility to tailor the website's appearance to specific branding requirements.",
  "Understanding the importance of cross-browser compatibility testing ensures that the website functions consistently across different web browsers. Learning how to identify and address compatibility issues helps deliver a smooth and reliable user experience, regardless of the browser or device being used.",
  "Implementing best practices for optimizing HTML, SASS, and Bootstrap 4 code contributes to improved website performance. Learning about techniques such as minification and code optimization can help reduce load times and enhance overall user satisfaction.",
];

const Scelint = () => {
  return (
    <Layout>
      <ProjectHero
        backgroundImage={Hero.backgroundImage}
        title={Hero.title}
        subHeading={Hero.subHeading}
        webLink={Hero.websiteLink}
      />
      <Overview listItemsData={OverviewData} />
      <TechStack techStack={techStcak} />
      <Learnings listItemsData={learningData} />
    </Layout>
  );
};
export default Scelint;