import Layout from "../Components/Layout/Layout";
import Learnings from "../Components/ProjectDetailed/Learnings";
import Overview from "../Components/ProjectDetailed/Overview";
import ProjectHero from "../Components/ProjectDetailed/ProjectHero";
import TechStack from "../Components/ProjectDetailed/TechStack";
const Hero = {
  title:"Movie DB",
  subHeading:"UI Development, Animations, API Intergration",
  backgroundImage:"/moviedb/movies.jpg",
  websiteLink:"https://652c38a134035d29dda4424a--delicate-sable-35e891.netlify.app/"
}
const OverviewData = [
"The project involves the development of an engaging and dynamic movie information website using the React framework, Styled Components for robust styling, and integration with The Movie Database (TMDb) API for fetching movie data.",
"This combination allows for the creation of a user-friendly interface that displays comprehensive movie information and provides a seamless browsing experience for users.",
"By leveraging the capabilities of React, Styled Components, and the TMDb API, the project successfully delivers a dynamic and interactive movie information website that caters to the needs of movie enthusiasts", 
"The seamless integration of these technologies enables the creation of a user-friendly and visually appealing interface, providing comprehensive movie information and fostering a vibrant community of users passionate about the world of cinema." 
];
const techStcak = [
  {
    icon:"/maven-silicon/strapi.webp",
    title:"STRAPI CMS"
  },
  {
    icon:"/technologies/icon-css.png",
    title:"CSS"
  },
  {
    icon:"/technologies/icon-sass.png",
    title:"SASS"
  },
  {
    icon:"/technologies/icon-js.png",
    title:"Javascript"
  },
  {
    icon:"/technologies/icon-react.png",
    title:"ReactJS"
  },
  {
    icon: "/technologies/icon-github.png",
    title:"GIT Hub"
  },
  {
    icon: "/technologies/icon-vs-code.png",
    title:"VS Code"
  },
  {
    icon:   "/maven-silicon/framer-motion.svg",
    title:"Framer Motion"
  },
];

const learningData = [
  "Understanding the fundamentals of React's component-based architecture is essential for creating dynamic and interactive user interfaces. Learning how to structure and manage components efficiently facilitates the development of scalable and maintainable applications.",
  "Mastering the use of Styled Components allows for the creation of consistent and visually appealing user interfaces. Learning how to leverage Styled Components for dynamic styling and theming enhances the flexibility and maintainability of the project's design system.",
  "Gaining proficiency in integrating external APIs, such as The Movie Database (TMDb) API, provides access to a wide range of data and services. Understanding how to fetch and handle data from external APIs enables the creation of dynamic and data-driven applications with up-to-date information and content.",
  "Understanding responsive design principles is crucial for creating user-friendly interfaces that adapt seamlessly to various devices and screen sizes. Learning how to implement responsive design techniques ensures optimal user experiences across different platforms and devices.",
  "Understanding how to incorporate interactive elements and personalized features enhances user engagement and promotes community participation. Learning how to implement user reviews, ratings, and recommendations fosters a more engaging and interactive user experience.",
  " Mastering error handling and loading state management ensures a smooth and uninterrupted user experience. Learning how to manage network errors and display informative loading states during data retrieval helps improve the overall reliability and performance of the application.",
  "Understanding data security best practices is essential for protecting sensitive user information and maintaining data integrity. Learning how to implement secure data handling practices helps build user trust and confidence in the application's security and privacy measures."
]


const MovieDB = () => {
  return(
    <Layout>
      <ProjectHero backgroundImage={Hero.backgroundImage} title={Hero.title} subHeading={Hero.subHeading} webLink={Hero.websiteLink} />
      <Overview listItemsData={OverviewData}/>
      <TechStack techStack={techStcak}/>
      <Learnings listItemsData={learningData}/>
    </Layout>
  )
}
export default MovieDB;