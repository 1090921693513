import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import ProjectCard from "./ProjectDetailed/ProjectCard";

const projectData = [
  {
    projectThumbnail: "/other-projects/banner-genex-f.jpg",
    projectLink: "https://genexdbs.com/",
    title:"GenexDBS",
    technology:"Wordpress, DIVI theme, UI Development"

  },
  {
    projectThumbnail: "/other-projects/limitless-xs.webp",
    projectLink: "https://limitlessaf.tech/",
    title:"LimitlessAF",
    technology:"Wordpress, DIVI theme, UI Development"
  },
  {
    projectThumbnail: "/other-projects/zastiagritech.jpg",
    projectLink: "https://zastiagritech.com/",
    title:"Zasti Agritech",
    technology:"Wordpress, DIVI theme, UI Development"
  },
  {
    projectThumbnail: "/other-projects/entrans.png",
    projectLink: "https://entrans.io/",
    title:"Entrans",
    technology:"Wordpress, DIVI theme, UI Development"
  },
  {
    projectThumbnail: "/other-projects/datautics-banner.jpg",
    projectLink: "  https://datautics.com/",
    title:"Datautics",
    technology:"Wordpress, DIVI theme, UI Development"
  },
];

const OtherProjects = () => {
  return (
    <section className="mt-5">
      <Container>
        <Row>
          <Col>
            <h4 className="text-3xl text-sm-4xl text-lg-6xl text-light text-start font-light mb-3">
              Other projects
            </h4>
            <Swiper
              slidesPerView={1}
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              className="pt-3 pb-5"
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
            >
              {projectData.map((data, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ProjectCard projectLink={data.projectLink}  image={data.projectThumbnail} title={data.title}  technology={data.technology}/>
                  </SwiperSlide>
                );
              })}
            </Swiper>

           
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default OtherProjects;
