import { Row, Col, Container } from "react-bootstrap";
import CircularButton from "./CircularButton";
import { Link } from "react-router-dom";
const projectData = [
  {
    projectThumbnail: "/thumbnails/maven.jpg",
    projectLink: "/maven-silicon",
    projectTitle:"Maven Silicon - UI Development, Animations, API Intergration",
  },
  {
    projectThumbnail: "/thumbnails/easybids-banner-new-a.jpg",
    projectLink: "/easybids",
    projectTitle:"Easybids - UI Development, React, Styled Components",
  },
  {
    projectThumbnail: "/thumbnails/scelint-thumb-new-x-compressed.jpg",
    projectLink: "/scelint",
    projectTitle:"Scelint - UI Development",
  },
  {
    projectThumbnail: "/thumbnails/zasti-new-thumb-compressed.jpg",
    projectLink: "/zasti",
    projectTitle:"Zasti - UI Development, Wordpress, Divi Theme",
  },
  {
    projectThumbnail: "/thumbnails/movie-db.jpg",
    projectLink: "/movies",
    projectTitle:"Movies DB - UI Development, React, API Intergration",
  },

];
const Projects = () => {
  return (
    <section className="py-3 overflow-hidden mt-5">
      <Container>
        <Row className="mb-3">
          <Col>
            <h4 className="text-4xl text-sm-6xl text-light font-light text-capitalize">Featured projects</h4>
          </Col>
        </Row>
        <Row className="g-4">
          {projectData.map((data, index) => {
            return (
              <Col sm={6} xl={4} key={index}>
                <Link to={data.projectLink}>
                <div className="mk-card overflow-hidden mk-hero-thumbnail mk-hero-thumbnail--project position-relative">
                  <img
                    src={data.projectThumbnail}
                    alt=""
                    className="w-100 h-100"
                  />
                  <CircularButton name="hello" pageLink={data.projectLink}/>
                </div>
                <p className="text-md mb-0 mt-2 text-light">{data.projectTitle}</p>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};
export default Projects;
