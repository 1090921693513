import { Col, Container, Image, Row } from "react-bootstrap";
const techIknowData = [
  "./technologies/icon-html.png",
  "./technologies/icon-css.png",
  "./technologies/icon-sass.png",
  "./technologies/icon-js.png",
  "./technologies/icon-react.png",
  // "./technologies/icon-firebase.png",
  "./technologies/icon-github.png",
  "./technologies/icon-vs-code.png",
  "./technologies/icon-wordpress.png",
  ]
const TechIKnow = () => {
  return(
    <section className="py-3 overflow-hidden mt-5">
      <Container>
        <Row className="mb-3">
          <Col>
            <h4 className="text-4xl text-sm-6xl text-light font-light">Things I Know</h4>
          </Col>
        </Row>
        <Row className="g-4">
         {
          techIknowData.map((data,index)=>{
            return(
              <Col xs={6} lg={3} key={index}>
                <div className="mk-card py-4 text-center">
                  <Image src={data} fluid/>
                </div>
                </Col>
            )
          })
         }
        </Row>
      </Container>
    </section>
  )
}
export default TechIKnow;

