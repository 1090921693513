import React, { useState } from "react";
import { motion } from "framer-motion";

const ProjectCard = ({ projectLink, image, title, technology }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <a
      href={projectLink}
      target="_blank"
      rel="noreferrer"
      className="d-block mk-card overflow-hidden mk-hero-thumbnail mk-hero-thumbnail--project position-relative"
    >
      <motion.div
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onHoverStart={handleHover}
        onHoverEnd={handleHover}
      >
        <img
          src={image}
          alt="card"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="position-absolute start-0 end-0 bottom-0 w-100 p-3"
            style={{
              background: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <h4 className="text-lg text-light   mb-0">{title}</h4>
            <p className="text-xs text-light opacity-5">{technology}</p>
          </motion.div>
        )}
      </motion.div>
    </a>
  );
};

export default ProjectCard;
