import { ArrowUpRight } from "react-bootstrap-icons";
import { Link} from 'react-router-dom';
const CircularButton = ({ pageLink }) => { 


  return (
    <Link
    className="mk-circular-btn rounded-circle border-0 d-flex align-items-center justify-content-center"
    to={pageLink}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 1.1 }}
  >

    <ArrowUpRight className="text-light"/>
  </Link>
  );
};

export default CircularButton;

