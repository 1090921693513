import { Container } from "react-bootstrap";
const ProjectHero = ({ backgroundImage, title, subHeading, webLink }) => {
  return (
    <section
      className="overflow-hidden position-relative mk-p__hero d-flex align-items-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Container className="mk-p__hero-container position-relative text-light">
        <h1 className=" text-4xl text-lg-6xl mb-0">{title}</h1>
        <p className="text-2xl text-lg-2xl">{subHeading}</p>
        <a
          href={webLink}
          target="_blank"
          rel="noreferrer"
          className="mk-button"
        >
         Check it out
        </a>
      </Container>
    </section>
  );
};
export default ProjectHero;
