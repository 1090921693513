import Layout from "../Components/Layout/Layout";
import Learnings from "../Components/ProjectDetailed/Learnings";
import Overview from "../Components/ProjectDetailed/Overview";
import ProjectHero from "../Components/ProjectDetailed/ProjectHero";
import TechStack from "../Components/ProjectDetailed/TechStack";
const Hero = {
  title: "Zasti",
  subHeading: "UI Development",
  backgroundImage: "/thumbnails/zasti-new-thumb-compressed.jpg",
  websiteLink: "http://zasti.ai/",
};
const OverviewData = [
  "The project involves the development of a dynamic and customizable website using the WordPress Divi theme, along with custom HTML, CSS, and JavaScript elements. The combination of these technologies enables the creation of a visually appealing and interactive website, with a focus on user engagement and seamless content management."
  ];
const techStcak = [
  {
    icon: "/technologies/icon-html.png",
    title: "HTML",
  },

  {
    icon: "/technologies/icon-css.png",
    title: "CSS",
  },

  {
    icon: "/technologies/icon-js.png",
    title: "Javascript",
  },
  {
    icon:"/technologies/icon-wordpress.png",
    title: "Wordpress",
  },
  {
    icon: "/zasti/divi.png",
    title: "Divi theme",
  },
  {
    icon: "/technologies/icon-vs-code.png",
    title: "VS Code",
  },
];

const learningData = [
" Understanding how to leverage the Divi theme's features and customization options is essential for creating visually stunning and feature-rich websites without extensive coding knowledge. Learning how to utilize Divi's drag-and-drop interface and module library can significantly streamline the design and development process.",
"Mastering the integration of custom HTML and CSS elements within the Divi theme allows for personalized and unique design implementations. Learning how to incorporate custom code seamlessly with the Divi builder empowers the creation of visually appealing and distinctive website sections and layouts.",
 "Gaining proficiency in integrating JavaScript functionalities within the Divi theme enables the implementation of interactive elements and dynamic content on the website. Learning how to use JavaScript for animations, form validations, and user interface enhancements can significantly enhance user engagement and overall browsing experience.",
 "Understanding responsive design principles and techniques ensures optimal user experiences across various devices and screen sizes. Learning how to create responsive layouts and elements within the Divi theme and custom code enhances the website's accessibility and usability, leading to better user satisfaction and engagement.",
 "Understanding how to effectively utilize WordPress as a content management system is crucial for efficient content management and publishing processes. Learning how to manage media files, create new pages, and update website content within the WordPress dashboard streamlines the overall content management workflow.",
 "Implementing SEO best practices within the WordPress Divi theme and custom code contributes to improved online visibility and search engine ranking. Learning how to optimize website content, meta tags, and URLs can significantly enhance the website's online presence and increase organic traffic, leading to better user engagement and conversions.",
 "Understanding how to integrate and manage WordPress plugins compatible with the Divi theme expands the website's functionality and feature set. Learning how to select and implement plugins for security, performance optimization, and additional functionalities can enhance the overall user experience and contribute to the website's success."
];

const Zasti = () => {
  return (
    <Layout>
      <ProjectHero
        backgroundImage={Hero.backgroundImage}
        title={Hero.title}
        subHeading={Hero.subHeading}
        webLink={Hero.websiteLink}
      />
      <Overview listItemsData={OverviewData} />
      <TechStack techStack={techStcak} />
      <Learnings listItemsData={learningData} />
    </Layout>
  );
};
export default Zasti;