import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = (props) => {
  return(
   <>
   <Navbar/>
   {props.children}
   <Footer/>
   </>
  )
}
export default Layout;